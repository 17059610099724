import { Navigate } from 'react-router'
import { useAppSelector } from '../redux/hooks'
import { ShowPasswordForm, ContactSupport } from "../components"
import { appInsights } from '../config'
import { Alert } from '@material-ui/lab'
import styled, { keyframes } from 'styled-components';
import { fadeInRight } from 'react-animations';
import { makeStyles } from '@material-ui/core'
import { McKSansMedium } from '../styles/fonts'
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/LanguageSelector"


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'center',
    },
    heading: {
        fontFamily: McKSansMedium,
        fontSize: '40px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '36px',
        }
    },
    contactSupport: {
        alignSelf: 'flex-end',
        marginRight: '3%',
        marginTop: '10%',
    },
}))

const fadeInAnimation = keyframes`${fadeInRight}`;
const FadeInAlert = styled(Alert) <any>`
  animation: ${(props: any) => props.seconds}s ${fadeInAnimation};
`;

const ShowPassword = () => {
    const classes = useStyles()
    const {t} = useTranslation('common');
    const { verifyDetails, password } = useAppSelector(state => state.newHire)
    appInsights.trackPageView({ name: 'DisplayPasswordPage' })

    // if new hire is not verified or password is empty redirect to home page
    if (!verifyDetails.isVerified || password.length < 2) {
        return <Navigate to="/" />
    }
    return (
        <div className={classes.root}>
            <h3 className={classes.heading}>{t('show_password.title')}</h3>
            <ShowPasswordForm password={password} />
            <div style={{ marginTop: '40px' }}>
                <FadeInAlert severity="info" style={{ marginBottom: '10px' }} seconds={2}>
                    <strong>{t('show_password.advice')}</strong>
                </FadeInAlert>
            </div>
            <div className={classes.contactSupport}>
                <ContactSupport />
            </div>
            <div>
                <LanguageSelector />
            </div>
        </div>
        
    )
}

export default ShowPassword