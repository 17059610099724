import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";  

const LanguageSelector = () => {
    const location = useLocation();
    const {i18n} = useTranslation();
    const queryParams = new URLSearchParams(location.search);
    const urlLang = queryParams.get('lang');
    let lng = i18n.language;

    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
    }

    useEffect(() => {
        if (urlLang !== lng && urlLang !== null) {
          i18n.changeLanguage(urlLang);
          window.location.reload();
        }
      }, []);

    return (
        <div>           
        </div>
    )
    
}

export default LanguageSelector