import { SyntheticEvent, MouseEvent } from "react"
import { Snackbar } from "@material-ui/core"
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface IProps {
    isError: boolean // if not isError then successful
    isOpen: boolean
    onClose(event: SyntheticEvent | MouseEvent, reason?: string): void
    message: string
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ActionAlert = ({ isError, isOpen, message, onClose }: IProps) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={isOpen}
            autoHideDuration={4000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity={isError ? 'error' : 'success'}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default ActionAlert