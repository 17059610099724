import { ApplicationInsights } from '@microsoft/applicationinsights-web';

function getApiUrlOrigin() {
    const baseApiUrl: string | undefined = process.env.REACT_APP_API_BASE_URL
    if (baseApiUrl?.includes('localhost')) {
        return `${baseApiUrl}/v1`
    }

    return `${baseApiUrl}/newhire/v1`
}

export const API = {
    NEWHIRE_URL: getApiUrlOrigin(),
}

 export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'f6a31db4-7877-487e-af13-41eb52ec6d1f',
    }
})

appInsights.loadAppInsights()
