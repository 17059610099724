import clsx from 'clsx'
import { useState, ChangeEvent, MouseEvent, FormEvent } from "react"
import {
    Button,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    makeStyles,
    CircularProgress,
} from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { ActionAlert } from "../"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { clearErrorMessage, validateAssetLabelAPICall } from "../../redux/reducers/newHire"
import { McK_ELECTRIC_BLUE } from '../../styles/colors'
import { useTranslation } from "react-i18next";

interface State {
    assetTag: string
    showAssetTag: boolean
}

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    primaryButton: {
        marginTop: '20px',
        width: '150px',
        alignSelf: 'center',
    },
    submitButton: {
        textTransform: 'capitalize',
        backgroundColor: McK_ELECTRIC_BLUE,
    },
    textField: {
        width: '290px'
    }
})

const AssetValidationForm = () => {
    const classes = useStyles()
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch()
    const { verifyDetails, userID, isLoading, errorMessage } = useAppSelector(state => state.newHire)

    const [values, setValues] = useState<State>({
        assetTag: '',
        showAssetTag: false,
    })

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // API call to verify serial number
        dispatch(validateAssetLabelAPICall(verifyDetails.verifyID, values.assetTag, userID))
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showAssetTag: !values.showAssetTag })
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, assetTag: event.target.value })
    }

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
                <FormControl className={classes.textField}>
                    <InputLabel htmlFor="standard-adornment-password">{t('get_its_label.field')}</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={values.showAssetTag ? 'text' : 'password'}
                        value={values.assetTag}
                        onChange={handleChange}
                        autoComplete="off"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle serial number visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showAssetTag ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {
                    isLoading ?
                        <CircularProgress className={classes.primaryButton} />
                        :
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={clsx(classes.primaryButton, classes.submitButton)}
                        >
                            {t('get_its_label.submit')}
                        </Button>
                }
                {
                    // show action alert only if error is not null
                    errorMessage !== null &&
                    <ActionAlert
                        isError={errorMessage !== null} // value is true if error is NOT null
                        message={errorMessage || 'Unexpected error'} // error message
                        isOpen={errorMessage !== null} // value is true if error is NOT null
                        onClose={() => {
                            dispatch(clearErrorMessage()) // on close button click set errorMessage to null
                        }}
                    />
                }
            </form>
        </div>
    )
}

export default AssetValidationForm