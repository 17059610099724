import {
    Container,
    makeStyles,
} from "@material-ui/core"

interface IProps {
    imgFirst?: boolean // show img part of the container first
    imgSrc: string
    children: JSX.Element
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: '3%',
        padding: '50px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: 0,
            flexDirection: 'column',
        },
    },
    formPart: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '50%',
        height: '100%',
        marginBottom: '50px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '100px',
        },
    },
    imgPart: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '50%',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            order: -1,
            width: '100%',
        },
    },
    srcImg: {
        width: '100%',
        height: '300px',
    }
}))


const DividedContainer = ({ children, imgSrc, imgFirst }: IProps) => {
    const classes = useStyles()
    return (
        <Container className={classes.root}>
            <div className={classes.formPart}>
                {children}
            </div>
            <div className={classes.imgPart} style={imgFirst ? { order: -1 } : {}}>
                <img className={classes.srcImg} alt="illiustration" src={imgSrc} />
            </div>
        </Container >
    )
}

export default DividedContainer