import { ICustomProperties, SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../config"


// log user actions
export function trackEvent(eventName: string, properties?: ICustomProperties | undefined): void {
    appInsights.trackEvent({
        name: eventName,
        properties,
    })
}

// log diagnostic scenario
export function trackStackTrace(message: string, logProperties?: ICustomProperties | undefined) {
    // if origin is localhost don't log in application insights
    if (!window.origin.includes("localhost")) {
        appInsights.trackTrace({
            message,
            severityLevel: SeverityLevel.Information
        }, logProperties)
    } else {
        console.log(message, logProperties)
    }
    
}

// log caught exceptions
export function trackException(error: Error | undefined): void {
    // if origin is localhost don't log in application insights
    if (!window.origin.includes("localhost")) {
        appInsights.trackException({
            error,
            severityLevel: SeverityLevel.Error,
        })
    } else {
        console.log(error)
    }
    
}