import { Alert, AlertTitle } from "@material-ui/lab"
import { useAppSelector } from "../redux/hooks"
import { makeStyles } from "@material-ui/core"
import { appInsights } from "../config"
import {useTranslation} from 'react-i18next'
import LanguageSelector from "../components/LanguageSelector"

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        padding: '20px 20px',
        alignItems: 'center',
        fontFamily: 'Roboto, sans-serif',
    },
})


const Error = () => {
    const {t} = useTranslation('errors');
    const classes = useStyles()
    const { errorMessage } = useAppSelector(state => state.newHire)
    appInsights.trackPageView({name: 'ErrorPage'})

    return (
        <div className={classes.root}>
            <h2>{t('went_wrong')}</h2>
            <Alert severity="error">
                <AlertTitle>{t('title')}</AlertTitle>
                <strong>{errorMessage}</strong>
            </Alert>
            <div>
                <LanguageSelector />
            </div>
        </div>
    )
}
export default Error