import { MouseEvent, useState } from "react"
import { FormControl, Input, InputAdornment, InputLabel, makeStyles, IconButton } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"

interface Props {
    password: string
}

interface State {
    showPassword: boolean
}

const useStyles = makeStyles({
    textField: {
        width: '290px',
    }
})

const ShowPasswordForm = ({ password }: Props) => {
    const classes = useStyles()
    const [values, setValues] = useState<State>({
        showPassword: false
    })

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl className={classes.textField}>
            <Input
                id="standard-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={password}
                readOnly={true}
                autoComplete="off"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle serial number visibility"
                            color="primary"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

export default ShowPasswordForm