import {
    makeStyles,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import AssetTagImg from "../../assets/images/asset-tag.png"
import { McK_ELECTRIC_BLUE } from "../../styles/colors"
import { McKSansLight } from "../../styles/fonts"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    heading: {
        fontFamily: McKSansLight,
        fontSize: '18px',
        color: McK_ELECTRIC_BLUE,
    },
    assetTagHint: {
        width: '290px',
        marginTop: '30px',
        marginBottom: '30px',
        '& img': {
            width: '250px',
            height: '150px',
        }
    }
})

const AssetLabelHint = () => {
    const {t} = useTranslation('common');

    const classes = useStyles()
    return (
        <div className={classes.assetTagHint}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.heading}>{t('get_its_label.help.label')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>{t('get_its_label.help.content')}</Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <img alt="asset tag sticker" src={AssetTagImg} />
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AssetLabelHint

