import { FunctionComponent } from "react"
import { Container, makeStyles } from "@material-ui/core"
import OnboardingImg from "../assets/illiustrations/onboarding.svg"
import { appInsights } from "../config"
import { McKSansLight } from "../styles/fonts"
import { Language } from "@material-ui/icons"
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/LanguageSelector"

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'center',
        padding: '20px 20px',
        width: '100%',
        fontFamily: McKSansLight,
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: 0,
            flexDirection: 'column',
        },
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '50%',
        height: '300px',
        [theme.breakpoints.down('xs')]: {
            order: -1,
            width: '100%',
        },
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
    },
    srcImg: {
        width: '100%',
        height: '100%',
    },
    text: {
        fontSize: '20px',
        textAlign: 'center',
    },
    adminButton: {
        marginTop: '20px',
    }
}))


const Home: FunctionComponent = () => {

    const {t} = useTranslation(['common','errors']);
    const classes = useStyles();
    appInsights.trackPageView({ name: 'HomePage' });

    return (
        <Container className={classes.root}>
            <div className={classes.imgContainer}>
                <img className={classes.srcImg} alt="main" src={OnboardingImg}></img>
            </div>
            <div className={classes.contentContainer}>
                <p className={classes.text}>
                    {t('landingPage')}
                </p>
            </div>
            <div>
                <LanguageSelector />
            </div>
        </Container>
    )
}

export default Home