import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { Navbar } from './components';
import { BrowserRouter as Router } from "react-router-dom";
import { Home, ShowPassword, Verify, Error, NotFound } from './pages';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/verify/:id' element={<Verify />} />
        <Route path='/show/password' element={<ShowPassword />} />
        <Route path="/error" element={<Error />} />
        <Route element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default App;